import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import search from "../../assets/images/search.svg";
import { v4 as uuidv4 } from "uuid";
import {
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setEquipment,
  setTypes,
  setTotalDoc,
  addfilter,
  saveQAContent,
  setToaster,
  setActivePage,
  setSearchId,
  saveSpellcorrection
} from "../../redux/actions";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import { searchApi } from "../apiCall";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const ResultSearchBox = (props) => {
  const NLP_URL = process.env.REACT_APP_NLP_BASE_URL;
  const NLP_IP = NLP_URL.slice(8);
  const WEBSOCKET_URL = `wss://${NLP_IP}/autocomplete/ws/autocomplete`;
  console.log("WEBSOCKET_URL", WEBSOCKET_URL);

  const [query, setQuery] = useState(props.searchedWord);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const websocketRef = useRef(null);
  const currentQueryRef = useRef("");

  useEffect(() => {
    setQuery(props.searchedWord);
  }, [props.searchedWord]);

  useEffect(() => {
    if (props.searchId) {
      const getDocs = async () => {
        props.saveSearchWord(query);
        props.addfilter(JSON.stringify({}));
        handleSessionOut();
        props.ableLoading(true);
        let filterData = [];

        if (query !== "") {
          var queryToExecute = {
            q: query,
            rows: 10,
            start: 0,
            filter: filterData,
            searchId: props.searchId,
          };
        }

        try {
          const res = await searchApi(queryToExecute);
          props.saveSearchResult(JSON.stringify(res.data.response.docs));
          res.data.response.authDocs?.length > 0
            ? props.saveSearchResultAuthDocs(
                JSON.stringify(res.data.response.authDocs)
              )
            : props.saveSearchResultAuthDocs("");
          if (res.data.equipment !== undefined) {
            props.setEquipment(res.data.equipment);
          }
          if (res.data.type !== undefined) {
            props.setTypes(res.data.type);
          }
          if (res.data.spellcheck !== undefined) {
            props.saveSpellcorrection(res.data.spellcheck);
          }
          props.setTotalDoc(res.data.response.numFound);
          props.setActivePage(1);
        } catch (error) {
          props.setToaster(true);
          if (
            error.response.data === "Access denied" ||
            error.response.status === 403
          ) {
            KeycloakUserService.doLogout();
          }
        } finally {
          props.ableLoading(false);
        }
      };

      getDocs();
    }
  }, [props.searchId]);

  useEffect(() => {
    websocketRef.current = new WebSocket(WEBSOCKET_URL);

    websocketRef.current.onmessage = (event) => {
      let suggestion = event.data;
      suggestion = suggestion.slice(6);
      if (suggestion.startsWith(currentQueryRef.current)) {
        setSuggestions((prev) => [...new Set([...prev, suggestion])]);
      }
    };

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowSuggestions(true);
    currentQueryRef.current = value;
    setSuggestions([]);

    if (value.length > 0) {
      if (
        websocketRef.current &&
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        websocketRef.current.send(value);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    if (!props.searchId) {
      props.setSearchId(uuidv4());
    }
  };

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      localStorage.clear();
      props.saveSearchWord("");
      window.location.reload(false);
      KeycloakUserService.doLogout();
      props.history.push({
        pathname: "/",
      });
    }
  }

  const onSearch = (e) => {
    e.preventDefault();
    setShowSuggestions(false);
    props.setSearchId(uuidv4());
  };

  const renderSuggestion = (suggestion) => {
    const matchIndex = suggestion.toLowerCase().indexOf(query.toLowerCase());
    if (matchIndex === -1) return suggestion;

    const before = suggestion.slice(0, matchIndex);
    const match = suggestion.slice(matchIndex, matchIndex + query.length);
    const after = suggestion.slice(matchIndex + query.length);

    return (
      <>
        {before}
        <span className="font-normal">{match}</span>
        <span className="font-bold">{after}</span>
      </>
    );
  };

  return (
    <form className="flex flex-col md:flex-row border-t border-b pt-7 pb-7 resultbox">
      <div className="font-bold text-xl leading-[22px] text-[#0971CE] flex flex-col items-center ml-0 md:ml-10">
        <div style={{ paddingTop: "10px", paddingLeft: "20px" }}>
          xIntelisearch
        </div>
      </div>
      <div className="relative">
        <div className="">
          <span className="material-symbols-outlined relative top-2.5 md:top-2 left-5 md:left-9 z-10 ">
            <img
              className="w-3 md:w-5 mb-2.5 material-symbols-outlined "
              src={search}
              alt="search logo"
            />
          </span>
          <input
            className="w-[320px] md:w-[564px] h-[22px] md:h-[44px] text-[5] pl-7 md:pl-14 focus:outline-none rounded-[28px] border shadow-[0px_0px_5px_0px_rgba(0,0,0,0.30)] placeholder-gray-400 text-[7px] md:text-[14px]"
            name="input"
            onChange={handleInputChange}
            type="text"
            value={query}
            placeholder="Ask me anything?"
            title="Search"
          />
        </div>
        {showSuggestions && suggestions.length > 0 && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-b-lg rounded-2xl shadow-lg">
            {suggestions.slice(0, 10).map((suggestion, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {renderSuggestion(suggestion)}
              </li>
            ))}
          </ul>
        )}
      </div>
      <Link to="/searchResult" className="">
        <div className="searchbuttononresultsearchbox pt-5 md:pt-0">
          <button
            type="submit"
            className="bg-[#0971CE] h-[45px] w-[120px] rounded-[20px] text-white"
            onClick={onSearch}
          >
            Search
          </button>
        </div>
      </Link>
    </form>
  );
};

const mapStateToProps = (state) => ({
  searchedWord: state.searchedWord,
  pinnedSearches: state.pinnedSearches,
  searchId: state.searchId,
  spellcheck: state.spellcheck,
});

export default connect(mapStateToProps, {
  saveSearchWord,
  saveSearchResultAuthDocs,
  saveSearchResult,
  setEquipment,
  setTypes,
  addfilter,
  setTotalDoc,
  setToaster,
  setActivePage,
  setSearchId,
  saveSpellcorrection,
})(ResultSearchBox);
