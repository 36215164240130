import React, { useState } from "react";
import {
  saveRelatedEquipmentFilter,
  saveKnowledgeSourceFilter,
  saveSearchResultAuthDocs,
  saveSearchResult,
  setTotalDoc,
  setEquipment,
  setTypes,
  saveSpellcorrection,
  saveSearchWord,
  setToaster,
  setActivePage,
  saveQAContent,
  addfilter,
  setSearchId
} from "../../redux/actions";
import { connect } from "react-redux";
import Filter from "../Filter/Filter";
import RaiseAFeedback from "../raiseAFeedback/raiseAFeedback";
import { searchApi } from "../apiCall.js";
import { v4 as uuidv4 } from "uuid";
import { Card, Popup } from "semantic-ui-react";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import { useNavigate } from "react-router";



const SearchResultOptions = (props) => {
  const [showFilterByOpenClose, setshowFilterByOpenClose] = useState("hide");
  const [knowledgeSource, setKnowledgeSource] = useState("");
  const [relatedEquipment, setRelatedEquipment] = useState("");
  const [newFilter, setnewFilter] = useState(false);
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);
  const [show, setShow] = useState(0);
  const [showSpellcheck, setShowSpellcheck] = useState(true);
  const [enableFeedbackForm, setEnableFeedbackForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const navigate = useNavigate();

  const [rerender, setrerender] = useState(false);
  const rendering = () => {
    setrerender(!rerender);
  };

  
  useState(() => {
    console.log("props", props);
  }, []);
  const getCorrectedPart = (searchedWord, spellcheck) => {
    const searchedWords = searchedWord.split(" ");
    const spellcheckedWords = spellcheck.split(" ");

    // Map through the spellchecked words and compare with the original searched words
    return spellcheckedWords.map((word, index) =>
      word !== searchedWords[index] ? <b><i key={index}>{`${word} `}</i></b> : `${word} `
    );
  };

  const [filter, setFilter] = useState("hide");
  const handleFilter = () => {
    if (filter === "hide") {
      setFilter("show");
      setshowFilterByOpenClose("show");
    } else {
      setFilter("hide");
      setshowFilterByOpenClose("hide");
    }
  };
  function applySeries(type, value) {
    if (value !== "") {
      props.applyFilter(type, value);
    } else {
      props.applyFilter(type, []);
    }
    setnewFilter(true);
  }

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      navigate("/");
      localStorage.clear();
      props.saveSearchWord("");
      window.location.reload(false);
      KeycloakUserService.doLogout();
    }
  }

  const showFeedbackForm = () => {
    setEnableFeedbackForm((enableFeedbackForm) => !enableFeedbackForm);
  };
  return (
    <div className="flex mt-6 h-[60px]">
      <div className="flex">
        <span className="text-[10px] md:text-[14px] leading-[10px] md:leading-[23px] font-medium ml-14">
          Most Relevant
        </span>
        <div className="h-1 w-1 bg-slate-500 rounded-lg mt-3 ml-1 mr-1 hidden md:block "></div>
        {/* <span className="ml-1 text-[10px]  md:text-[14px] leading-[10px] md:leading-[23px]">
          You have searched for "{props.searchedWord}" here are the results (
          {knowledgeSource !== "" || relatedEquipment !== ""
            ? props.filteroutputlength
            : JSON.parse(props?.searchResult).length}
          )
        </span> */}
        <div className="ml-1 text-[10px] md:text-[14px] leading-[10px] md:leading-[23px]">
          {(props.searchedWord !== props.spellcheck) && showSpellcheck  ? (
            <div style={{ fontSize: "15px" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <div>{`Showing results for`}</div>
                <div>
                  <button onClick={
                    ()=>{
                      setShowSpellcheck(false);
                    }
                  } style={{ color: "#1a0dab" }} className="spellcheck">
                    {getCorrectedPart(props.searchedWord, props.spellcheck)}
                  </button>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <div>{`Search instead for`}</div>
                <div>
                  <button onClick={async (e) => {
                    const getDocs = async (e) => {
                      props.saveSearchWord(props.searchedWord);
                      props.saveQAContent("");
                      let filterData = [];
                      props.addfilter(JSON.stringify({}));
                      
                      props.setSearchId(`${uuidv4()}_incorrect`);
                      
                    };

                    getDocs();
                    }} style={{ color: "#1a0dab" }} className="spellcheck">
                    {`${props.searchedWord}`}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            `You have searched for "${showSpellcheck?props.searchedWord:props.spellcheck}", here are the results`
          )}
        </div>
      </div>
      {/* filter with dropdown in which the dropdown contains the option provided from backend */}
      <div className="flex ml-auto mr-14">
  <div>
    {props.totalDoc !== 0 && (
      <Popup
        content="Click to filter documents"
        position="top center"
        inverted
        trigger={
          <button
            onClick={(e) => handleFilter()}
            id="filterButton"
            className={`flex p-1 w-20 h-10 mr-1 ml-auto ${
              showFilterByOpenClose === "hide"
                ? "text-[#0971CE] bg-white"
                : "text-white bg-[#0971CE]"
            }  border-2 rounded-md`}
          >
            <span className="ml-3 mt-1 font-inter">Filter</span>
          </button>
        }
      />
    )}

    <div
      className={`absolute ${
        featureFlag["Q&A engine"]
          ? "top-[48.5rem] right-[18.6rem]"
          : "top-[18.5rem] right-[20.6rem]"
      } md:top-[17.5rem]  md:right-[48.6rem]  h-0 w-0 z-50 mt-3 md:mt-0 ml-6 ${
        filter === "hide" ? "hidden" : "block"
      }`}
    >
      <div
        className={
          featureFlag["Q&A engine"]
            ? `w-[23rem] lg:w-[30rem]  p-2 pl-3 pr-3 bg-white text-black rounded-lg border shadow-lg mt-12 sm:mt-0  ml-[-5rem]   
          md:ml-[-9.7rem]`
            : `w-[23rem] lg:w-[30rem]  p-2 pl-3 pr-3 bg-white text-black rounded-lg border shadow-lg mt-12 sm:mt-0  ml-[-5rem]   
          md:ml-[15.7rem]`
        }
      >
        <Filter
          equipment={props.documentequipments}
          type={props.documenttypes}
          applySeries={applySeries}
          rerender={rendering}
        />
      </div>
    </div>
  </div>
</div>


      {/* {props.totalDoc === 0 && <RaiseAFeedback />} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    searchResult: state.searchResult,
    searchResultAuthDocs: state.searchResultAuthDocs,
    filteroutputlength: state.filteroutputlength,
    documentequipments: state.documentequipments,
    documenttypes: state.documenttypes,
    appliedFilter: state.appliedFilter,
    newFilter: state.newFilter,
    featureFlag: state.featureFlag,
    totalDoc: state.totalDoc,
    spellcheck: state.spellcheck,
    ableLoading: state.ableLoading,
  };
};

export default connect(mapStateToProps, {
  saveKnowledgeSourceFilter,
  saveRelatedEquipmentFilter,
  saveSearchResultAuthDocs,
  saveSearchResult,
  setTotalDoc,
  saveSearchWord,
  setEquipment,
  setTypes,
  saveSpellcorrection,
  setToaster,
  setActivePage,
  saveQAContent,
  addfilter,
  setSearchId,
})(SearchResultOptions);
