import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { featureFlagAPi } from "./components/apiCall";
import { setFeatureflag, setToaster } from "./redux/actions/index";
import Dashboard from "./pages/Dashboard";
import { Routes, Route } from "react-router-dom";
import SearchResultPage from "./pages/SearchResultPage";
import SearchResultDetailPage from "./pages/SearchResultDetailPage";
import AllPinnedSearchPage from "./pages/AllPinnedSearchPage";
import Toaster from "./components/Toaster/Toaster";

function App(props) {
  useEffect(() => {
    // Fetch fature list to be enabled / disabled
    featureFlagAPi()
      .then((data) => {
        let featureObj = {};
        for (let i = 0; i < data.data.features.length; i++) {
          featureObj[data.data.features[i].featureId] =
            data.data.features[i].state === "enabled" ? true : false;
        }
        props.setFeatureflag(featureObj);
      })
      .catch(() => {
        props.setToaster(true);
      });
  }, []);
  return (
    <div className="z-0 app">
      {props.toaster && <Toaster />}
      <Routes>
        {/* home page route  */}
        <Route exact path="/" element={<Dashboard />}></Route>

        {/* search result page route here search result is shown  */}
        <Route
          exact
          path="/searchResult"
          element={<SearchResultPage />}
        ></Route>
        {/* search result detail page route here detail of search will be shown  */}
        <Route
          exact
          
          path="/searchResultDetail/:type/:id"
          element={<SearchResultDetailPage />}
        ></Route>

        {/* view all pinned searches */}
        <Route
          exact
          path="/allpinnedsearches"
          element={
            <div>
              <AllPinnedSearchPage />
            </div>
          }
        />
        {/* at this route all items will be shown  */}
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    toaster: state.toaster,
  };
};

export default connect(mapStateToProps, {
  setFeatureflag,
  setToaster,
})(App);
