import React from "react";
import threedots from "../../assets/images/kebabmenu.png";
import { Loader } from "semantic-ui-react";
import KeycloakUserService from "../../keycloak/KeycloakUserService";
const StepContent = ({
  steps,
  roles,
  handleFeedback,
  handleFeedbackdropdown,
  feedbackDropdownStep,
  feedbackCollection,
  addFeedback,
  addButtonDisableFunc,
  viewFeedback,
  viewButtonDisableFunc,
  idToHighlight,
}) => {
  roles = roles?roles:KeycloakUserService.hasRole("user");
  return (
    <>
      <div
        className="flex justify-between items-center bg-[#FFF6DB] px-5 py-"
        style={{
          backgroundColor: steps.stepId === idToHighlight ? "#EEF5FC" : "",
        }}
      >
        <h1 id={steps.stepId} className="m-0">
          {steps.stepTitle}
        </h1>

        <div className="">
          {roles && (
                          <button
                          // src={threedots}
                          className="w-20 h-9 mr-1 ml-auto bg-white text-blue-600 border-blue-600 border-2 rounded-md"
                          onClick={() => handleFeedback(steps)}
                        >Feedback</button>
          )}
          {handleFeedbackdropdown &&
            feedbackDropdownStep.stepId === steps.stepId && (
              <div
                key={steps.stepId}
                className="relative top-[4px] right-[80px] md:right-[120px]  mb-[-64px] h-0 w-0 p-0.5 pl-3 pr-3 text-black rounded-lg  shadow-lg "
              >
                {feedbackCollection.length > 0 ? (
                  <div className="w-36 p-2 bg-white text-black font-inter rounded-sm border-slate-100 border-2     ">
                    <div
                      onClick={(e) => addFeedback(e)}
                      disabled={addButtonDisableFunc(steps.stepId)}
                      className={`pl-0 md:pl-5 pb-3 pt-3 text-xs shadow-sm  ${
                        addButtonDisableFunc(steps.stepId)
                          ? "bg-slate-400 text-slate-200 pointer-events-none "
                          : "bg-[#EEF5FC] text-[#0971CE] cursor-pointer"
                      }`}
                    >
                      Add Feedback
                    </div>

                    <div
                      onClick={(e) => viewFeedback(e)}
                      disabled={viewButtonDisableFunc(steps.stepId)}
                      className={`pl-0 md:pl-5 pb-3 pt-3 mt-2 text-xs shadow-sm   ${
                        viewButtonDisableFunc(steps.stepId)
                          ? "bg-slate-400 text-slate-200 pointer-events-none"
                          : "bg-[#EEF5FC] text-[#0971CE] cursor-pointer "
                      } `}
                    >
                      View Feedback
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        marginTop: "-2.5rem",
                        marginLeft: "6rem",
                      }}
                    >
                      <Loader active inline />{" "}
                    </div>
                  </>
                )}
              </div>
            )}
        </div>
      </div>
      <div
        key={steps.stepId}
        className="proced-desc"
        dangerouslySetInnerHTML={{
          __html: steps.stepDescription[0],
        }}
      ></div>
      <div className="video">
        {steps.media && <video src={steps?.media[0]} controls />}
      </div>
    </>
  );
};

export default StepContent;
