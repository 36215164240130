/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, Icon } from "semantic-ui-react";

const Sidebar = (highlights) => {
  const [clickedToc, setClickedToc] = useState();
  const docDetail = useSelector((state) => state.docDetail);

  let id = docDetail.meta && docDetail.meta[0].id.replace("/meta#", "");

  useEffect(() => {
    
    docDetail.meta && updateHash({ id: id });

    
  }, []);
  const updateHash = (highlight) => {
    console.log("highlight", highlight);
    setClickedToc(highlight.id);
    document.location.hash = `highlight-${highlight.id}`;
  };

  return (
    <div className="sidebar">
      <div className="description" />
      <Accordion>
        <Accordion.Content active={true}>
          <ul className="sidebar__highlights">
            {highlights.tableContents
              .filter(
                (highlight) =>
                  highlight.content.text !== "Summary from search display"
              )
              .map((highlight, index) => (
                <div>
                  {highlight.isOutLine === true && (
                    <div
                      className="tableOfContents"
                      id={highlight.id}
                      style={{
                        backgroundColor:
                          clickedToc === highlight.id ? "#EEF5FC" : "",
                      }}
                    >
                      <div>
                        <li
                          key={index}
                          className="sidebar__highlight"
                          onClick={() => {
                            updateHash(highlight);
                          }}
                        >
                          <div>
                            <strong>{highlight.comment.text}</strong>
                            {highlight.content.text ? (
                              <blockquote
                                className="sidebar-text"
                                style={{
                                  color:
                                    clickedToc === highlight.id
                                      ? "#034E91"
                                      : "",
                                }}
                              >
                                {highlight.content.text}
                              </blockquote>
                            ) : null}
                            {highlight.content.image ? (
                              <div
                                className="highlight__image"
                                style={{ marginTop: "0.5rem" }}
                              >
                                <img
                                  src={highlight.content.image}
                                  alt={"Screenshot"}
                                />
                              </div>
                            ) : null}
                          </div>
                        </li>
                      </div>

                      <div>
                        <Icon
                          name={"chevron right"}
                          className="arrow-icon"
                          style={{
                            color: clickedToc === highlight.id ? "#034E91" : "",
                          }}
                          onClick={() => {
                            updateHash(highlight);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </ul>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};
export default Sidebar;
