import React from "react";
import info_circle from "../../assets/images/info-circle.png";
import { useNavigate } from "react-router";
import { Card, Popup } from "semantic-ui-react";

const PinnedDocsHeader = (props) => {
  const navigate = useNavigate(); // Removed unnecessary 'new' keyword
  const handlwShowButton = () => {
    if (props.atPinnedSearch) {
      navigate("/");
    } else {
      navigate("/allpinnedsearches");
    }
  };

  return (
    <div className="titlesearch">
      <div className="pin-title-icon">
        <h4>Your pinned searches({props.length}) </h4>

        <Popup
          trigger={<img className="pin-popup" src={info_circle} />}
          content="You can pin 10 of your previous searches to the homepage"
          position="top center"
          inverted
        />
      </div>
      <div>
        {props.length > 0 && (
          <Popup
            trigger={
              <button
                onClick={(e) => {
                  handlwShowButton();
                }}
              >
                {props.atPinnedSearch ? "Show less" : "Show all"}
              </button>
            }
            content={
              props.atPinnedSearch
                ? "Click to collapse the pinned searches"
                : "Click to view all pinned searches"
            }
            position="top left"
            inverted
          />
        )}
      </div>
    </div>
  );
};

export default PinnedDocsHeader;
