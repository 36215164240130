import React, { useState } from "react";
import Logo from "../Logo/Logo";
import Useroptions from "../../components/Useroptions/Useroptions";
import { Popup } from "semantic-ui-react"; // Importing Popup
import { useNavigate } from "react-router";

const Navbar = (props) => {
  const [show, setShow] = useState("hide");
  const [btn, setBtn] = useState(0);
  const navigate = useNavigate();

  return (
    // This component is the navbar of the app
    <div className="navbar">
      <div className="ml-[1.1rem]  md:ml-2 mt-7 md:mt-5">
        <Logo />
      </div>
      <div className={`flex flex-row ml-auto block`}></div>
      <div className={`ml-auto`}>
        <Popup
          content="User Options" // Text to display in the popup
          position="bottom center" // Position of the popup
          inverted
          trigger={<div><Useroptions /></div>} // Wrapping Useroptions component
        />
      </div>
    </div>
  );
};

export default Navbar;
