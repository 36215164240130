import React from "react";
import { connect } from "react-redux";
import lex_logo from "../../assets/images/logo_lexx.png";
import { useNavigate } from "react-router";
import "../../assets/index.css";
import { logUserAction } from "../apiCall";

const Logo = (props) => {
  const navigate = new useNavigate();
  const onclicklogo = () => {
    logUserAction({
      documentId: props.docDetail.id,
      route: 'lexx icon',
    });
    if (!props?.chatgpt) {
      navigate("/");
    }
  };

  return (
    <button
      className={props?.chatgpt ? "defaultbutton" : ""}
      onClick={onclicklogo}
    >
      <img className="w-9 md:w-auto" src={lex_logo} alt="lex_Logo" />
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(Logo);

